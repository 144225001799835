import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

import { PageTitle } from '../components/title';

import { GridRow } from '../components/widgets/grid';

import Play from './svg/logos/play_badge.svg';
import AppStore from './svg/logos/app_store.svg';

const openAppStore = () => {
  window.open('https://itunes.apple.com/gb/app/switchsmith/id1194663008?mt=8');
}

const openPlayStore = () => {
  window.open('https://play.google.com/store/apps/details?id=com.relops.switchsmith&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1');
}

const Download = () =>
  <div className="pb4-ns">

    <Helmet title="Download Apps | SwitchSmith">
      <link rel="canonical" href="https://switchsmith.com/download" />
    </Helmet>

    <PageTitle title='Download'/>

    <div className="row">
      <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
      <div className="col-xs-10 col-sm-10 col-md-8 col-lg-8">
        <p>Download the SwitchSmith app for iOS or Android.</p>
      </div>
    </div>

    <GridRow>
      <div className="mt0-ns mt4 mb4 row">
        <div className="col-xs-6 col-sm-5 col-md-4 col-lg-3 flex items-center">
          <AppStore style={{ height: '40px' }} onClick={openAppStore} />
        </div>
        <div className="col-xs-6 col-sm-5 col-md-4 col-lg-3 flex items-center">
          <Play style={{ height: '60px' }} onClick={openPlayStore} />
        </div>
      </div>
    </GridRow>

  </div>

const DownloadPage = () => (
  <Layout>
    <Download/>
  </Layout>
)

export default DownloadPage;